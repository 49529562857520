// Updated BuyRoom.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';

const BuyRoom = () => {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [order, setOrder] = useState(null);
  const [error, setError] = useState('');
  const [timeLeft, setTimeLeft] = useState(15 * 60);
  const [isTimeout, setIsTimeout] = useState(false);
  const navigate = useNavigate();

  const plans = [
    { id: 'A', name: 'A方案', price: 1, rooms: 1 },
    { id: 'B', name: 'B方案', price: 10, rooms: 10 },
    { id: 'C', name: 'C方案', price: 100, rooms: 100 },
  ];

  const handlePlanClick = async (plan) => {
    try {
      setSelectedPlan(plan);
      setError('');
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create_order`, 
        { plan: plan.id }, 
        { 
          headers: {
            'Content-Type': 'application/json',
            'Session-Id': localStorage.getItem('session_id')
          },
          withCredentials: true 
        }
      );

      if (response.data && response.data.order_number) {
        setOrder(response.data);
        setTimeLeft(15 * 60);
        setIsTimeout(false);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      const errorMessage = error.response?.data?.message || t('Failed to create order. Please try again.');
      setError(errorMessage);
      setOrder(null);
    }
  };

  useEffect(() => {
    if (order && !isTimeout) {
      const checkPayment = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/check_payment/${order.order_number}`,
            {
              headers: {
                'Session-Id': localStorage.getItem('session_id')
              },
              withCredentials: true
            }
          );
          
          if (response.data.status === 'success') {
            navigate('/user_panel');
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
        }
      };

      const intervalId = setInterval(checkPayment, 5000);
      return () => clearInterval(intervalId);
    }
  }, [order, navigate, isTimeout]);

  useEffect(() => {
    if (timeLeft > 0 && !isTimeout) {
      const timerId = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else if (timeLeft === 0) {
      setIsTimeout(true);
    }
  }, [timeLeft, isTimeout]);

  const handleRetryOrder = () => {
    setOrder(null);
    setSelectedPlan(null);
    setIsTimeout(false);
    setTimeLeft(15 * 60);
    setError('');
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(order.wallet_address);
      alert(t('Wallet address copied to clipboard!'));
    } catch (err) {
      console.error('Failed to copy:', err);
      setError(t('Failed to copy wallet address'));
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-900">{t('Buy Room')}</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}
        
        {!order ? (
          <div className="space-y-4">
            {plans.map((plan) => (
              <button
                key={plan.id}
                onClick={() => handlePlanClick(plan)}
                className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none"
              >
                {`${plan.name} (${plan.rooms} rooms - ${plan.price} USDT)`}
              </button>
            ))}
          </div>
        ) : isTimeout ? (
          <div className="text-center">
            <p className="text-red-600 text-2xl font-bold">{t('Order timeout. Please reorder.')}</p>
            <button
              onClick={handleRetryOrder}
              className="w-full px-4 py-2 mt-4 text-white bg-red-600 rounded-lg hover:bg-red-700"
            >
              {t('Retry Order')}
            </button>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-3xl font-bold mt-4">{order.amount.toFixed(2)} USDT</p>
            <div className="flex justify-center mt-4">
              <QRCodeSVG value={order.wallet_address} size={256} />
            </div>
            <div
              onClick={handleCopyToClipboard}
              className="mt-4 cursor-pointer p-2 bg-gray-200 rounded-lg hover:bg-gray-300"
            >
              {order.wallet_address}
            </div>
            <p className="mt-2">{t('Order Number')}: {order.order_number}</p>
            <p className="text-2xl font-bold text-red-600 mt-4">{formatTime(timeLeft)}</p>
          </div>
        )}
        
        <button
          onClick={() => navigate('/user_panel')}
          className="w-full px-4 py-2 mt-4 text-white bg-gray-600 rounded-lg hover:bg-gray-700"
        >
          {t('Back to User Panel')}
        </button>
      </div>
    </div>
  );
};

export default BuyRoom;